<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                        <b-col xl="12" lg="12" sm="12">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
                                  <b-row>
                                    <b-col sm="12">
                                      <b-col sm="12">
                                        <b-table-simple small hover bordered responsive>
                                          <b-thead>
                                            <b-tr>
                                              <b-th>{{ $t('teaGardenConfig.fertilizer_name') }}</b-th>
                                              <b-th>{{ $t('teaGarden.allocation_qty_m_ton') }}</b-th>
                                              <b-th>{{ $t('teaGarden.unit_price_m_ton') }}</b-th>
                                              <b-th>{{ $t('globalTrans.total') }} {{ $t('globalTrans.taka') }}</b-th>
                                            </b-tr>
                                          </b-thead>
                                          <b-tbody>
                                            <b-tr v-for="(item, index) in formData.fertilizer_allocations" :key="'fer-' + index">
                                              <b-td>{{ getFertilizerName(item.fertilizer_id) }}</b-td>
                                              <b-td>{{ $n(item.receipt_qty) }}</b-td>
                                              <b-td>
                                                <ValidationProvider name="Price Per (M. Ton)" vid="unit_price" :rules="'required|min_value:0'">
                                                  <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="unit_price"
                                                    slot-scope="{ valid, errors }"
                                                  >
                                                    <b-form-input
                                                      id="reason"
                                                      v-model="item.unit_price"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      @keypress="isNumber"
                                                      @input="setTotalPrice(index)"
                                                      min="0"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                      {{ errors[0] }}
                                                    </div>
                                                  </b-form-group>
                                                </ValidationProvider>
                                              </b-td>
                                              <b-td>{{ $n(item.total_receipt_price) }}</b-td>
                                            </b-tr>
                                          </b-tbody>
                                        </b-table-simple>
                                      </b-col>
                                    </b-col>
                                  </b-row>
                                    <div class="row">
                                    <div class="col-sm-3"></div>
                                    <div class="col text-right">
                                        <b-button type="submit" variant="primary" class="mr-2">{{ $t('teaGardenService.price_entry') }}</b-button>
                                        &nbsp;
                                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-8')">{{ $t('globalTrans.cancel') }}</b-button>
                                    </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </b-col>
                    </b-row>
                </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { fertilizerIndentPriceEntry } from '../../api/routes'
import { helpers } from '@/utils/helper-functions'

export default {
    name: 'FormLayout',
    props: ['id'],
    created () {
      this.formData = this.getFormData()
    },
    watch: {
    },
    computed: {
        loading () {
          return this.$store.state.commonObj.loading
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    data () {
        return {
            loader: false,
            formData: {
              id: 0,
              fertilizer_allocations: []
            }
        }
    },
    methods: {
      getFormData () {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify(tmpData))
      },
      async register () {
          this.loader = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadinState = { loading: false, listReload: true }
          result = await RestApi.putData(teaGardenServiceBaseUrl, `${fertilizerIndentPriceEntry}/${this.id}`, this.formData)
          this.$store.dispatch('mutateCommonProperties', loadinState)
          if (result.success) {
               this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })
              this.$bvModal.hide('modal-8')
          } else {
              this.$refs.form.setErrors(result.errors)
          }
          this.loader = false
      },
      isNumber (evt) {
        helpers.isNumber(evt)
      },
      getFertilizerName (id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterFertilizerList.find(item => item.value === id)
        if (obj) {
          return this.currentLocale === 'en' ? obj.text_en : obj.text_bn
        }
      },
      setTotalPrice (index) {
        const item = this.formData.fertilizer_allocations[index]
        item.total_price = item.unit_price * item.verified_qty
        item.total_receipt_price = item.unit_price * item.receipt_qty
      }
    }
}
</script>
